<template>
  <div>
    <div id="pageRegistUser" class="mainContents">
      <section class="titleArea">
        <h2>会員登録</h2>
        <!-- /.titleArea -->
      </section>

      <form @submit.prevent="checkForm">
        <ul>
          <li v-for="(error, index) in errors" :key="index">
            {{ error }}
          </li>
        </ul>
        <div class="registProfileArea">
          <div class="inner">
            <div class="flow">
              <ul>
                <li>情報登録</li>
                <li class="active">
                  登録内容の確認
                </li>
                <li>登録完了</li>
              </ul>
            </div>
            <div class="group">
              <dl class="name">
                <div>
                  <dt>姓</dt>
                  <dd class="inputWrap">
                    <input
                      v-model="user.lastname"
                      :disabled="sending"
                      type="text"
                      readonly="readonly"
                    >
                  </dd>
                </div>
                <div>
                  <dt>名</dt>
                  <dd class="inputWrap">
                    <input
                      v-model="user.firstname"
                      :disabled="sending"
                      type="text"
                      readonly="readonly"
                    >
                  </dd>
                </div>
                <div>
                  <dt>姓（カタカナ）</dt>
                  <dd class="inputWrap">
                    <input
                      v-model="user.lastname_kana"
                      :disabled="sending"
                      type="text"
                      readonly="readonly"
                    >
                  </dd>
                </div>
                <div>
                  <dt>名（カタカナ）</dt>
                  <dd class="inputWrap">
                    <input
                      v-model="user.firstname_kana"
                      :disabled="sending"
                      type="text"
                      readonly="readonly"
                    >
                  </dd>
                </div>
              </dl>
              <ul class="gender readonly">
                <li>
                  <label><input
                    v-model="user.gender"
                    type="radio"
                    value="male"
                    checked="checked"
                    disabled="disabled"
                  ><span>男</span></label>
                </li>
                <li>
                  <label><input
                    v-model="user.gender"
                    type="radio"
                    value="female"
                    disabled="disabled"
                  ><span>女</span></label>
                </li>
              </ul>
            </div>
            <div class="group">
              <dl class="birthday">
                <dt>生年月日</dt>
                <dd>
                  <p class="inputWrap year">
                    <input
                      v-model="user.birthday.year"
                      :disabled="sending"
                      type="text"
                      readonly="readonly"
                    >
                  </p>
                  <span>年</span>
                </dd>
                <dd>
                  <p class="inputWrap">
                    <input
                      v-model="user.birthday.month"
                      :disabled="sending"
                      type="text"
                      readonly="readonly"
                    >
                  </p>
                  <span>月</span>
                </dd>
                <dd>
                  <p class="inputWrap">
                    <input
                      v-model="user.birthday.day"
                      :disabled="sending"
                      type="text"
                      readonly="readonly"
                    >
                  </p>
                  <span>日</span>
                </dd>
              </dl>
            </div>
            <div class="group">
              <dl class="prefectures">
                <dt>お住まいの都道府県</dt>
                <dd class="inputWrap">
                  <input
                    v-model="user.prefecture"
                    type="text"
                    readonly="readonly"
                  >
                </dd>
              </dl>
            </div>
            <div class="group">
              <dl class="mail">
                <div>
                  <dt>メールアドレス</dt>
                  <dd class="inputWrap">
                    <input
                      v-model="user.email"
                      type="text"
                      readonly="readonly"
                    >
                  </dd>
                </div>
              </dl>
            </div>
            <div class="group">
              <dl class="password">
                <div>
                  <dt>パスワード</dt>
                  <dd class="inputWrap">
                    <input
                      v-model="user.password"
                      :disabled="sending"
                      type="password"
                      readonly="readonly"
                    >
                  </dd>
                </div>
              </dl>
            </div>

            <div class="buttonArea">
              <p class="submit">
                <button :disabled="sending" type="submit" name="button">
                  登録する
                </button>
              </p>
              <!-- /.buttonArea -->
            </div>
          </div>
          <!-- /.registProfileArea -->
        </div>
      </form>

      <!-- /.mainContents -->
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data () {
    return {
      sending: false,
      errors: {},
      user: {}
    }
  },
  computed: {},
  created: function () {
    this.user = this.$store.getters.getCreateUser
  },
  methods: {
    checkForm: function (e) {
      this.sending = true

      if (Object.keys(this.errors).length > 0) {
        this.sending = false
        return false
      }

      return this.create()
    },
    create () {
      this.sending = true
      const bday =
        this.user.birthday.year +
        '-' +
        this.user.birthday.month +
        '-' +
        this.user.birthday.day
      this.$store
        .dispatch('create', {
          email: this.user.email,
          key: this.user.key,
          lastname: this.user.lastname,
          firstname: this.user.firstname,
          lastname_kana: this.user.lastname_kana,
          firstname_kana: this.user.firstname_kana,
          gender: this.user.gender,
          birthday: bday,
          prefecture: this.user.prefecture,
          password: this.user.password
        })
        .then(() => {
          this.$router.push({ name: 'complete' })
        })
        .catch(err => {
          this.sending = false
          this.errors.response = err.response.data
        })
    }
  }
}
</script>

<style scoped></style>
